import React, { useEffect } from 'react';

import useAxios from 'axios-hooks';

import { Button, List, ListItem, makeStyles } from '@tomfxs/pukeko';

import Port from './Port';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  subtile: {
    color: theme.palette.input.list
  },
  listitem: {
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  connect: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    height: 96,
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    borderRadius: 10,
    position: 'absolute',
    top: '25vh',
    left: 'calc(50% - 200px)'
  },
  refresh: {
    marginTop: 16,
    color: theme.palette.error.contrastText,
  },
  addwrapper: {
    marginTop: 16,
    textAlign: 'center',
  },
  add: {
    fontSize: 40,
    backgroundColor: theme.palette.contrast,
    color: theme.palette.success.contrastText,
  }
}));

const Menu = ({ url, config, refetch, onSelect }) => {
  const classes = useStyles();

  const [connect, setConnect] = React.useState(false);

  useEffect(() => {
    if (config) {
      setConnect(false);
      return;
    }
    const interval = setInterval(() => {
      setConnect(!config);
    }, 1000);
    return () => clearInterval(interval);
  }, [config]);


  const [{ loading: saving }, post] = useAxios({
    url: `${url}/new`,
    method: 'POST'
  }, { manual: true });

  const handleAdd = () => {
    post({});
    refetch();
  }

  const handleSelect = (soa) => {
    onSelect(['', {}]);
    setTimeout(() => onSelect(soa), 0);
  }


  return (
    <>
      {config ? (
        <List style={{ paddingTop: 0 }}>
          {Object.entries(config).map((item, index) => {
            const [name, values] = item;
            const { description, mode } = values;
            return (
              <ListItem
                key={index}
                className={classes.listitem}
                onClick={() => handleSelect(item)}
              >
                <div>
                  <div>{name}</div>
                  <div className={classes.subtile}>
                    <div>{description}</div>
                    <div>{mode} {mode === 'SOCKET' && <Port url={url} name={name} />}</div>
                  </div>
                </div>
              </ListItem>
            );
          })}
          <div className={classes.addwrapper}>
            <Button
              className={classes.add}
              icon="add"
              onClick={handleAdd}
            />
          </div>
        </List>
      ) : null
      }
      {connect && (
        <div id="error" className={classes.connect}>
          <div>Bitte Prozedur SOA.Config in marah aufrufen</div>
          <Button
            className={classes.refresh}
            onClick={() => window.location.reload()}
          >
            Aktualisieren
          </Button>
        </div>
      )}
    </>
  );
}

export default Menu;
