import React, { useState } from 'react';

import { Layout, Theme } from '@tomfxs/pukeko';

import themes from './themes';

const UI = ({ style, children }) => {
  const defaultTheme = 'dark';

  const [theme, setTheme] = useState(defaultTheme);
  const handleTheme = (newTheme) => {
    setTheme(newTheme);
  }

  function getTheme(name) {
    let muiTheme = {};
    if (name === 'auto') {
      const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
      window.matchMedia("(prefers-color-scheme: dark)").addListener(e => e.matches && setTheme(getTheme('dark')));
      window.matchMedia("(prefers-color-scheme: light)").addListener(e => e.matches && setTheme(getTheme('light')));
      if (isDarkMode) {
        muiTheme = themes.dark;
      }
      muiTheme = themes.light;
    } else {
      muiTheme = themes[name];
    }
    return muiTheme;
  }

  return (
    <Theme theme={getTheme(theme)}>
      <Layout style={{ height: '100%', overflow: 'hidden', paddingRight: 0}}>
        {React.cloneElement(children, { theme, setTheme: handleTheme })}
      </Layout>
    </Theme>
  );
}

export default UI;
