import React from 'react';

import useAxios from 'axios-hooks';

const Version = ({ host, url, config = {} }) => {
  const { socket_port, tls_certificate_file } = config;

  const protocol = tls_certificate_file ? 'https' : 'http';
  const socket = `${protocol}://${host}:${socket_port}`;

  console.log(`${url}/getversion/${btoa(socket)}`);
  const [{ data: datasoa = {} }] = useAxios({
    url: `${url}/getversion/${btoa(socket)}`,
  });
  const { _version = '' } = datasoa;

  return (
    <>
      {_version && <span> &#8226; V {_version}</span>}
    </>
  );
}

export default Version;
