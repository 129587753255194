import { colors } from '@tomfxs/pukeko';

const colorPalette = [];
for (let i = 900; i >= 100; i -= 100) {
  colorPalette.push(colors.amber[i]);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  palette: {
    mode: 'dark',
    action: {
      active: colors.blueGrey[100],
      secondary: colors.blueGrey[100],
    },
    text: {
      primary: colors.blueGrey[100],
      secondary: colors.blueGrey[200],
      icon: colors.grey[50]
    },
    background: {
      default: colors.blueGrey[900],
      gradient: colors.blueGrey[800],
      paper: colors.blueGrey[700],
      paperDark: colors.blueGrey[800],
      src: '/img/furniture4.svg',
      minWidth: '60vw'
    },
    primary: {
      contrastText: colors.blueGrey[100],
      dark: colors.blueGrey[700],
      light: colors.blueGrey[500],
      main: colors.blueGrey[200],
    },
    secondary: {
      contrastText: colors.blueGrey[100],
      main: colors.blueGrey[300],
    },
    input: {
      main: colors.blueGrey[800],
      disabled: colors.blueGrey[700],
      color: colors.blueGrey[100],
      list: colors.blueGrey[600],
      border: colors.blueGrey[100],
      borderradius: 5
    },
    overlay: {
      main: '#ffffff20'
    },
    success: {
      contrastText: 'white',
      main: colors.green[600]
    },
    warning: {
      main: colors.orange[400],
      contrastText: 'white',
    },
    warnings: {
      contrastText: colors.yellow[700],
    },
    articles: {
      main: colors.lightGreen[500],
      sub: colors.cyan[500]
    },
    note: {
      contrastText: colors.blueGrey[900],
      main: colors.amber[500]
    },
    errors: {
      contrastText: colors.blueGrey[100],
      main: colors.red[700],
    },
    drop: {
      target: colors.green[500],
      over: colors.green[800],
      color: 'white'
    },
    icon: colors.blueGrey[100],
    contrast: colors.orange[400],
    subtitle: colors.blueGrey[200],
    bookmark: colors.yellow[700],
    border: colors.orange[300],
    accent: colors.blueGrey[300],
    swatch: [
      colors.blue[800],
      colors.green[800],
      colors.purple[800],
      colors.amber[800],
      colors.red[800],
      colors.yellow[800],
      colors.cyan[800],
    ],
    groups: [
      colors.orange[600],
      colors.green[600],
      colors.purple[600],
      colors.amber[600],
      colors.lightBlue[600],
      colors.teal[600],
      colors.cyan[600],
      colors.lime[600],
      colors.pink[600],
      colors.deepOrange[600],
    ],
  },
  props: {
    MuiPaper: {
      elevation: 0
    }
  },
  typography: {
    useNextVariants: true,
  },
  chart: {
    colors: colorPalette
  },
  colorPalette,
  switch: {
    track: colors.blueGrey[200],
    checked: colors.green[500],
    trackChecked: colors.green[300]
  },
  logo: '/img/sauterundheld_logo_web_RGB.svg',
  components: {

  },
};
