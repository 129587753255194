import { colors } from '@tomfxs/pukeko';

const colorPalette = [
  '#012A4A',
  '#013A63',
  '#01497C',
  '#014F86',
  '#2A6F97',
  '#2C7DA0',
  '#468FAF',
  '#61A5C2',
  '#61A5C2',
  '#A9D6E5',
];

const blue = [
  '#ebeffa', // 0
  '#9dc5ff', // 1
  '#f5f4fe', // 2
  '#2a72ff', // 3
  '#fbffff', // 4
  '#d9dff3', // 5
  '#014F86', // 6
  '#2b66c8', // 7
];

const grey = [
  '#99a4bf', // 0
  '#545454', // 1
  '#0a142f', // 2
  '#efefef', // 3
  '#f5f5fb', // 4
  '#dcdcdc', // 5
  '#fdfdfd', // 6
  '#afb0b7' // 7

]


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  palette: {
    mode: 'light',
    action: {
      active: colors.indigo[500],
      secondary: colors.blueGrey[300],
    },
    text: {
      primary: grey[2],
      secondary: grey[1],
      icon: colors.blue[50]
    },
    background: {
      default: blue[0],
      gradient: blue[5],
      paper: grey[6],
      paperDark: '#ffffff',
      src: '/img/furniture2.svg',
      minWidth: '60vw'
    },
    primary: {
      contrastText: grey[1],
      dark: blue[5],
      light: blue[5],
      main: grey[1],
    },
    secondary: {
      contrastText: grey[1],
      main: blue[6],
    },
    input: {
      main: '#ffffff',
      disabled: grey[4],
      color: colors.blueGrey[700],
      list: grey[6],
      border: blue[3],
      inputborder: `2px solid ${grey[7]}`,
      borderradius: 0
    },
    overlay: {
      main: '#00000020'
    },
    success: {
      contrastText: colors.blueGrey[50],
      main: colors.green[600]
    },
    warnings: {
      contrastText: colors.yellow[700],
    },
    warning: {
      main: colors.orange[400],
      contrastText: 'white',
    },
    errors: {
      contrastText: colors.blueGrey[50],
      main: colors.red[700],
    },
    note: {
      contrastText: colors.blueGrey[900],
      main: colors.yellow[500]
    },
    articles: {
      main: colors.green[800],
      sub: colors.cyan[800]
    },
    drop: {
      target: colors.green[500],
      over: colors.green[800],
      color: colors.blueGrey[50]
    },
    icon: blue[6],
    contrast: blue[7],
    subtitle: blue[6],
    bookmark: colors.yellow[700],
    border: colors.orange[300],
    accent: colors.blueGrey[600],
    swatch: [
      '#012A4A',
      '#013A63',
      '#01497C',
      '#014F86',
      '#2A6F97',
      '#2C7DA0',
      '#468FAF',
      '#61A5C2',
      '#61A5C2',
      '#A9D6E5',
    ],
    groups: [
      colors.orange[600],
      colors.green[600],
      colors.purple[600],
      colors.amber[600],
      colors.lightBlue[600],
      colors.teal[600],
      colors.cyan[600],
      colors.lime[600],
      colors.pink[600],
      colors.deepOrange[600],
    ],
  },
  props: {
    MuiPaper: {
      elevation: 0
    },
    MuiButton: {
      variant: "outlined",
      color: "secondary"
    }
  },
  typography: {
    useNextVariants: true,
  },
  chart: {
    colors: colorPalette
  },
  switch: {
    track: colors.blueGrey[200],
    checked: colors.green[500],
    trackChecked: colors.green[300]
  },
  logo: '/img/sauterundheld_logo_web_RGB.svg',
  components: {

  },
};
