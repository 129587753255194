import React from 'react';

import ini from 'ini';
import useAxios from 'axios-hooks';

import { makeStyles } from '@tomfxs/pukeko';

import Version from './Version';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
  }
}));

const Port = ({ url, name }) => {
  const classes = useStyles();

  const [{ data, loading }] = useAxios(`${url}/getsoa/${name}`);
  const config = !loading && data ? ini.parse(data) : {};
  const { socket_port, socket_ip } = config;
  const hosts = socket_ip ? socket_ip.split(';') : [];

  return (
    <>
      <span className={classes.root}>{socket_port}</span>
      {hosts.map((host, i) => (
        <Version key={i} config={config} host={host} url={url} />
      ))}
    </>
  );
}

export default Port;
