import React, { useEffect } from 'react';

import useAxios from 'axios-hooks';

import {
  Button, Checkbox, Container, Group, Input, Line, makeStyles,
} from '@tomfxs/pukeko';

import ini from './lib/ini';

import label from './labels';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 8
  },
  inputStyle: {
    color: theme.palette.primary.contrastText,
    boxShadow: `0 0 0px 1000px ${theme.palette.background.paper} inset`,
    borderRadius: 5,
    paddingLeft: 8
  },
  checkbox: {
    marginTop: -12
  },
  save: {
    marginTop: -8,
    color: theme.palette.contrast
  },
  label: {
    marginTop: 12
  },
  line: {
    marginTop: '32px !important'
  }
}));

const Content = ({ url, soa = [], onSave }) => {
  const [soaname = '', values = ''] = soa;

  const classes = useStyles();

  const [name, setName] = React.useState(soaname);

  useEffect(() => {
    setName(soaname);
  }, [soaname]);

  const [{ data, loading }] = useAxios(`${url}/getsoa/${soaname}`);
  const config = !loading && data ? ini.parse(data) : null;

  const props = config ? Object.entries(config) : [];
  const valueprops = config ? Object.entries(values) : [];

  const getValues = (id) => {
    const cfg = document.getElementById(id);
    const elements = Array.from(cfg.elements);

    const objects = elements.map((element) => {
      let { id, type, value, checked } = element;
      if (type === 'checkbox') {
        return { [id]: checked ? 'Y' : 'N' };
      } else {
        return { [id]: value };
      }
    });
    return Object.assign({}, ...objects);
  }

  const handleSave = () => {
    const cfg = { name, soaname, values: getValues('cfg') };
    const soa = { name, values: getValues('soa') };

    onSave({ cfg, soa });
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  return (
    <Container style={{ padding: 8 }}>
      <Group xs={11}>
        <Input
          value={name}
          fullWidth
          disableUnderline
          className={classes.input}
          onChange={handleNameChange}
        />
      </Group>
      <Group xs={1} style={{ textAlign: 'right' }}>
        {name && <Button onClick={handleSave} icon="save" className={classes.save} />}
      </Group>

      <Group xs={12} style={{ marginTop: 16, marginLeft: 8 }} >
        <form id="cfg">
          <Container>
            {valueprops.map((item) => {
              const [key, value] = item;
              return (
                <Container key={key}>
                  <Group xs={4}>
                    <div className={classes.label}>{label(key)}</div>
                  </Group>
                  <Group xs={8} key={key}>
                    {value === 'Y' || value === 'N' ? (
                      <Checkbox
                      id={key}
                      checked={value === 'Y'}
                      rootStyle={{ marginTop: 4 }}
                    /> ): (
                      <Input
                        id={key}
                        value={value}
                        fullWidth
                        disableUnderline
                        className={classes.input}
                        inputStyle={classes.inputStyle}
                      />
                    )}
                  </Group>
                </Container>
              );
            })}
          </Container>
        </form>

        {props.length > 0 && <Line className={classes.line} />}

        <Group xs={12} style={{ marginTop: 32 }}>
          <form id="soa">
            <Container>
              {props.map((item) => {
                const [key, value] = item;
                return (
                  <Container key={key}>
                    <Group xs={4}>
                      <div className={classes.label}>{label(key)}</div>
                    </Group>
                    <Group xs={8} key={key}>
                      {value === 'Y' || value === 'N' ? (
                        <Checkbox
                          id={key}
                          checked={value === 'Y'}
                          rootStyle={{ marginTop: 4 }}
                        />
                      ) : (
                        <Input
                          id={key}
                          value={value}
                          fullWidth
                          disableUnderline
                          className={classes.input}
                          inputStyle={classes.inputStyle}
                        />
                      )}
                    </Group>
                  </Container>
                );
              })}
              </Container>
          </form>
        </Group>
      </Group>
    </Container>
  );
}

export default Content;
