const labels = {
  autorevive: 'Autom. Neustart',
  autostart: 'Autostart',
  description: 'Beschreibung',
  mode: 'Modus',
  start_delay: 'Startverzögerung',

  alert_mail_from: 'Absender Alert-Mail',
  alert_mail_to: 'Empfänger Alert-Mail',
  alert_mail_server: 'SMTP-Server Alert-Mail',
  alert_mail_user: 'User-Name Alert-Mail',
  alert_mail_password: 'Passwort Alert-Mail',
  alert_mail_tls: 'TLS Alert-Mail',
  alert_lines: 'Zeilen Alert-Mail',
  applogoptions: 'Log-Optionen',
  applogpath: 'Log-Pfad',
  c16_connection_shared: 'Verbindung teilen',
  c16_database: 'Datenbank',
  c16_password: 'Passwort',
  c16_server: 'Server',
  c16_user: 'Benutzer',
  c16_procedure: 'Start-Prozedur',
  c16_proc_cache_kb: 'Cache in kb',
  c16_proc_extended: 'Erweitert (nur bei Druck-SOA!)',
  ip_connection_limit: 'IP-Verbindungs-Limit',
  socket_ip: 'IP-Adressen',
  socket_port: 'Port',
  socket_timeout: 'Timeout',
  tls_certificate_file: 'Zertifikat',
  tls_private_key_file: 'Schlüssel',
};

const label = (key) => {
  return labels[key] || key;
};

export default label;
