import dark from './dark';
import light from './light';

/*
import orange from './orange';
import cyan from './cyan';
import green from './green';
*/

const themes = {
  dark,
  light
  /*
  orange,
  cyan,
  green,
  */
};

export default themes;
