import React, { useState } from 'react';

import useAxios from 'axios-hooks';

import { Container, Group, makeStyles } from '@tomfxs/pukeko';

import ini from './lib/ini';

import Content from './Content';
import Menu from './Menu';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 88px)',
    textAlign: 'left',
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: 16,
    marginLeft: -4
  },
  menu: {
    paddingTop: '0px !important',
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
    overflow: 'auto',
  },
  content: {
    paddingTop: 0,
    height: '100%',
    overflow: 'auto',
  }
}));

const Config = ({ url }) => {
  const classes = useStyles();
  const [soa, setSoa] = useState([]);

  const [{ loading: saving, error }, post] = useAxios({
    url: `${url}/savecfg`,
    method: 'POST'
  }, { manual: true });

  const [{ data, loading }, refetch] = useAxios(`${url}/getconfig`);
  const config = !loading && data ? ini.parse(data) : null;

  const refresh = async () => {
    await refetch();
  };

  const handleSave = async ({ cfg, soa }) => {
    const { name, values } = soa;
    const { name: cfgname, soaname, values: cfgvalues } = cfg;

    delete config[soaname];
    const cfgsave = { ...config, [cfgname]: cfgvalues };

    await post({
      url: `${url}/savecfg/cfg`,
      data: ini.stringify(cfgsave, { whitespace: true })
    });

    await post({
      url: `${url}/savecfg/${name}`,
      data: ini.stringify(values, { whitespace: true })
    });

    refetch();
  };

  return (
    <Container className={classes.root}>
      {error && <div className={classes.connect}> {error.message} </div>}
      <Group xs={4} className={classes.menu}>
        <Menu url={url} config={config} refetch={refresh} onSelect={soa => setSoa(soa)} />
      </Group>
      <Group xs={8} className={classes.content}>
        {soa && <Content url={url} soa={soa} onSave={handleSave} />}
      </Group>
    </Container>
  )
}

export default Config
