import React from 'react';

import Main from './Main';
import UI from './UI';

const App = () => {
  return (
    <UI>
      <Main />
    </UI>
  )
}

export default App
