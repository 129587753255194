import React from 'react';

import Cookies from 'js-cookie';

import { Group, Input, makeStyles } from '@tomfxs/pukeko';

import Config from './Config';

import './Main.css';

const useStyles = makeStyles(theme => ({
  inputStyle: {
    color: theme.palette.primary.contrastText,
    boxShadow: `0 0 0px 1000px ${theme.palette.background.paper} inset`,
    borderRadius: 5,
    paddingLeft: 8
  },
}));

const Main = () => {
  const [url, setUrl] = React.useState(Cookies.get('url') || 'http://localhost:8000');

  const classes = useStyles();
  Cookies.set('url', url, { expires: 365 });

  return (
    <>
      <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
        <Group xs={12}>
          <img src="/img/logo.svg" height={32} alt="logo" />
        </Group>
        <Group xs={12} style={{ textAlign: 'left' }}>
          <Input
            value={url}
            style={{ position: 'relative', left: 20, width: 220 }}
            inputStyle={classes.inputStyle}
            disableUnderline
            onChange={e => setUrl(e.target.value)}
          />
        </Group>
        <Config url={url} />
      </div>
    </>
  );
}

export default Main;
